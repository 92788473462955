<template>
  <div>
    <div class="title">
      <img src="../../../assets/imgs/c_tit_icon1.png" />
      <span>用户中心</span>
    </div>

    <div class="data-content">
      <div class="data-content__fst">
        <div class="fst-title">
          核心数据概况<img src="../../../assets/imgs/mydata-icon1.png" />
        </div>
        <div class="fst-content">
          <div class="text-list">
            公司名称：<span>{{ overviewOfCoreData.companyName }}</span>
          </div>
          <div class="text-content">
            <div class="text-list">
              用户代码：<span>{{ overviewOfCoreData.userCode }}</span>
            </div>
            <div class="text-list">
              手机号：<span>{{ overviewOfCoreData.mobilePhoneNumber }}</span>
            </div>
          </div>
          <div class="text-content">
            <div class="text-list">
              今年交易总金额：<span>{{
                overviewOfCoreData.totalTransactionAmountThisYear
              }}</span>
            </div>
            <div class="text-list">
              用户等级：<span>{{ overviewOfCoreData.userLevel }}</span>
            </div>
          </div>
          <div class="text-content">
            <div class="text-list">
              当月交易总金额：<span>{{
                overviewOfCoreData.totalTransactionAmountOfTheMonth
              }}</span>
            </div>
            <div class="text-list">
              {{baseFirm}}信用评分：<span>{{
                overviewOfCoreData.jiajiaCreditScore
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="data-content__sec">
        <div class="sec-title">
          智能全景数据<img src="../../../assets/imgs/mydata-icon2.png" />
        </div>
        <div class="sec-content">
          <img src="../../../assets/imgs/mydata-img.png" />
        </div>
      </div>
    </div>

    <div class="todo-list">
      <div class="title">
        待办事宜<img src="../../../assets/imgs/wait-icon.png" />
      </div>
      <div class="content">
        <div class="content-fst">
          <img src="../../../assets/imgs/wait-saler.png" />
          <div class="block">
            <div class="num">{{ sale.pendingContract }}</div>
            <div class="label">待处理合同</div>
          </div>
          <div class="block">
            <div class="num">{{ sale.toBeDelivered }}</div>
            <div class="label">待发货</div>
          </div>
        </div>
        <div class="content-sec">
          <div class="block">
            <div class="num">{{ purchase.toBePaid }}</div>
            <div class="label">待付款</div>
          </div>
          <div class="block">
            <div class="num">{{ purchase.pendingContract }}</div>
            <div class="label">待处理合同</div>
          </div>
          <img src="../../../assets/imgs/wait-buyer.png" />
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-fst">
        <img src="../../../assets/imgs/jfooter_gary1.png" />
        <div class="content">
          <p>
            客服热线<span>{{ others.customerServiceHotline }}</span>
          </p>
          <p>
            工作时间<span>{{ others.workTime }}</span>
          </p>
        </div>
      </div>
      <div class="footer-sec" v-if="isYLT">
        COPYRIGHT © 金泰-宜潞通电商平台www.jtylt.com <a href="http://beian.miit.gov.cn" style="color: #2b99ff"  target="_blank">苏ICP备2020069202号-1</a>
      </div>
      <div class="footer-sec" v-else>
        COPYRIGHT © 加佳大宗供应链管理系统www.jiajiaex.com <a href="http://beian.miit.gov.cn" style="color: #2b99ff"  target="_blank">沪ICP备16010047号-3</a>
      </div>
    </div>
    <el-dialog
      class="contract"
      title="新合同提醒"
      :visible.sync="dialogVisible"
      :width="'575px'"
      :before-close="handleClose"
    >
      <div v-if="totalBuy > 0">
        <span
          >尊敬的用户您好，您有{{
            totalBuy
          }}条新的采购合同，请点击查看按钮进入合同列表</span
        >
        <el-button type="primary" size="mini" @click="checkBuy" style="margin-left: 10px;">查看</el-button>
      </div>
      <div v-if="totalSell > 0" style="margin-top: 10px;">
        <span
          >尊敬的用户您好，您有{{
            totalSell
          }}条新的销售合同，请点击查看按钮进入合同列表</span
        >
        <el-button type="primary" size="mini" @click="checkSell" style="margin-left: 10px;"
          >查看</el-button
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      overviewOfCoreData: {},
      purchase: {},
      sale: {},
      others: {},
      dialogVisible: false,
      totalBuy: '',
      totalSell: ''
    }
  },
  computed: {
    baseFirm () {
      return process.env.VUE_APP_BASE_FIRM
    },
    isYLT () {
      return process.env.VUE_APP_ENV === 'proylt'
    }
  },
  created () {
    this.getUserInfo()
    this.getContractNotice()
    // this.$bus.$on("getTotalBuy", data => {
    //   if (data > 0) {
    //     this.totalBuy = data;
    //     this.dialogVisible = true;
    //   }
    // });
    // this.$bus.$on("getTotalSell", data => {
    //   if (data > 0) {
    //     this.totalSell = data;
    //     this.dialogVisible = true;
    //   }
    // });
  },
  methods: {
    getUserInfo () {
      let params = {
        process_id: '00000200', // 流程ID
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {}
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          const { data } = res
          this.overviewOfCoreData = data.overviewOfCoreData
          this.purchase = data.purchase
          this.sale = data.sale
          this.others = data.others
        })
        .catch(err => {})
    },
    getContractNotice () {
      let params = {
        process_id: '00000231', // 流程ID
        nodeInfo: {}
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params, true)
        .then(res => {
          const { data } = res
          this.totalBuy = data.totalBuy
          this.totalSell = data.totalSell
          if (data.totalBuy > 0 || data.totalSell > 0) {
            this.dialogVisible = true
          }
          // this.$bus.$emit("getTotalBuy", data.totalBuy);
          // this.$bus.$emit("getTotalSell", data.totalSell);
          // this.$store.commit('setTotalBuy', data.totalBuy);
          // this.$store.commit('setTotalSell', data.this.totalBuy);
        })
        .catch(err => {})
    },
    handleClose (done) {
      done()
    },
    checkBuy () {
      this.$router.push({ path: '/bContractManagement/bContractManagement' })
      this.dialogVisible = false
    },
    checkSell () {
      this.$router.push({ path: '/contractManagement/contractManagement' })
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #d12c1e;
  height: 58px;
  line-height: 58px;
  border-bottom: 1px solid #ecf0f1;
}
.data-content {
  display: flex;
  margin: 20px 0 20px 0;
  .data-content__fst,
  .data-content__sec {
    flex: 1;
    margin-right: 35px;
    height: 252px;
    float: left;
    background: #cbf5f5;
    box-shadow: 1px 2px 6px 0px rgb(0 0 0 / 20%);
    padding: 30px 70px;
    box-sizing: border-box;
    .fst-title,
    .sec-title {
      height: 42px;
      color: #333;
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px solid #333;
      margin-bottom: 25px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .fst-content {
      font-size: 14px;
      color: #333;
      .text-list {
        line-height: 24px;
      }
      .text-content {
        display: flex;
        .text-list {
          width: 50%;
          line-height: 24px;
        }
      }
      .text-list span {
        color: #0084d8;
      }
    }
  }
}
.todo-list {
  padding: 30px 70px;
  background: #fff8d8;
  box-shadow: 1px 2px 6px 0px rgb(0 0 0 / 20%);
  margin-right: 35px;
  .title {
    height: 42px;
    color: #333;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #333;
    margin-bottom: 25px;
    position: relative;
    img {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .content-fst,
    .content-sec {
      display: flex;
      .block {
        width: 120px;
        margin-right: 30px;
        text-align: center;
      }
    }
    .content-fst {
      .num {
        color: #6b89ce;
        font-weight: bold;
        text-align: center;
        font-size: 80px;
        line-height: 80px;
      }
    }
    .content-sec {
      .num {
        color: #bf7ff1;
        font-weight: bold;
        text-align: center;
        font-size: 80px;
        line-height: 80px;
      }
    }
  }
}
.footer {
  .footer-fst {
    display: flex;
    padding: 70px 35px;
    .content {
      border-left: 1px solid #ecedee;
      padding-left: 20px;
    }
    p {
      height: 32px;
      color: #aeaeae;
      font-size: 14px;
      font-weight: 300;
      margin: 0;
      span {
        height: 22px;
        line-height: 22px;
        font-size: 18px;
        margin-left: 20px;
        font-weight: 500;
      }
    }
  }
  .footer-sec {
    height: 52px;
    line-height: 52px;
    color: #797979;
    font-size: 12px;
    padding-left: 35px;
    border-top: 1px solid #f1f1f2;
  }
}
</style>
